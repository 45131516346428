<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 563.4 415.9"
    @click="animate()">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="161.35"
        y1="161.43"
        x2="259.64"
        y2="63.14"
        gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#434e58" stop-opacity="0" />
        <stop offset="1" stop-color="#3d4852" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="143.95"
        y1="237.18"
        x2="277.04"
        y2="104.1"
        xlink:href="#linear-gradient"/>
      <linearGradient
        id="linear-gradient-3"
        x1="250.34"
        y1="331.01"
        x2="333.65"
        y2="247.71"
        xlink:href="#linear-gradient"/>
      <linearGradient
        id="linear-gradient-4"
        x1="327.79"
        y1="346.24"
        x2="441.54"
        y2="232.48"
        xlink:href="#linear-gradient"/>
      <symbol id="triangle" data-name="triangle" viewBox="0 0 46.5 41.5">
        <polygon
          points="23.3 4.5 4.5 37 42 37 23.3 4.5"
          fill="none"
          stroke="#3d4852"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="9"
          opacity="0.2"/>
      </symbol>
      <symbol id="circle" data-name="circle" viewBox="0 0 42 42">
        <circle cx="21" cy="21" r="21" fill="#3d4852" opacity="0.2" />
      </symbol>
    </defs>
    <title>ui-illustration</title>
    <g id="content">
      <g>
        <g id="window-1" ref="window1">
          <path
            d="M124,102.3H297a4.3,4.3,0,0,1,4.3,4.3v13a0,0,0,0,1,0,0H119.6a0,0,0,0,1,0,0v-13A4.3,4.3,0,0,1,124,102.3Z"
            opacity="0.2"
            fill="url(#linear-gradient)"/>
          <path
            d="M119.6,128.4H301.4a0,0,0,0,1,0,0v82.6a4.3,4.3,0,0,1-4.3,4.3H124a4.3,4.3,0,0,1-4.3-4.3V128.4A0,0,0,0,1,119.6,128.4Z"
            opacity="0.2"
            fill="url(#linear-gradient-2)"/>
          <g>
            <circle cx="260.5" cy="200.7" r="2.2" fill="#3d4852" />
            <circle cx="273.6" cy="200.7" r="2.2" fill="#3d4852" />
            <circle cx="286.6" cy="200.7" r="2.2" fill="#3d4852" />
          </g>
          <circle
            cx="154.4"
            cy="163.2"
            r="17.4"
            fill="none"
            stroke="#3490dc"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4.35"/>
          <g opacity="0.2">
            <rect
              x="184.4"
              y="152.6"
              width="70.6"
              height="4.35"
              fill="#3d4852"/>
            <rect
              x="184.4"
              y="161.3"
              width="79.3"
              height="4.35"
              fill="#3d4852"/>
            <rect x="184.4" y="170" width="74.9" height="4.35" fill="#3d4852" />
          </g>
        </g>
        <g id="window-2" ref="window2">
          <g opacity="0.2">
            <path
              d="M318.8,345.9H267a4.3,4.3,0,0,1-4.3-4.3V237.2a4.3,4.3,0,0,1,4.3-4.3h51.7Z"
              fill="url(#linear-gradient-3)"/>
          </g>
          <g opacity="0.2">
            <path
              d="M440.1,345.9H327.5V232.8H440.1a4.3,4.3,0,0,1,4.3,4.3V341.6A4.3,4.3,0,0,1,440.1,345.9Z"
              fill="url(#linear-gradient-4)"/>
          </g>
          <g>
            <circle cx="277.7" cy="331.2" r="2.2" fill="#3d4852" />
            <circle cx="290.7" cy="331.2" r="2.2" fill="#3d4852" />
            <circle cx="303.8" cy="331.2" r="2.2" fill="#3d4852" />
          </g>
          <g opacity="0.2">
            <rect
              x="271.4"
              y="250.2"
              width="38.7"
              height="4.35"
              fill="#3d4852"/>
            <rect x="271.4" y="258.9" width="30" height="4.35" fill="#3d4852" />
            <rect
              x="271.4"
              y="267.6"
              width="34.3"
              height="4.35"
              fill="#3d4852"/>
            <rect
              x="271.4"
              y="276.3"
              width="26.1"
              height="4.35"
              fill="#3d4852"/>
            <rect x="271.4" y="285" width="34.3" height="4.35" fill="#3d4852" />
          </g>
          <polygon
            points="385.9 258.5 364.5 295.7 407.4 295.7 385.9 258.5"
            fill="none"
            stroke="#e3342f"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4.35"/>
          <g opacity="0.2">
            <rect
              x="369.5"
              y="306.8"
              width="32.9"
              height="4.35"
              fill="#3d4852"/>
            <rect
              x="375.3"
              y="315.5"
              width="21.3"
              height="4.35"
              fill="#3d4852"/>
          </g>
        </g>
        <g id="list" ref="list">
          <g>
            <circle cx="337" cy="136.1" r="7.7" fill="#3d4852" />
            <rect
              x="329.7"
              y="154.9"
              width="14.5"
              height="14.5"
              fill="#3d4852"/>
            <polygon
              points="336.7 180.7 327.5 196.6 345.9 196.6 336.7 180.7"
              fill="#3d4852"/>
            <rect
              x="364.5"
              y="131.7"
              width="96.7"
              height="8.7"
              fill="#3d4852"
              opacity="0.2"/>
            <rect
              x="364.5"
              y="157.8"
              width="96.7"
              height="8.7"
              fill="#3d4852"
              opacity="0.2"/>
            <rect
              x="364.5"
              y="183.9"
              width="96.7"
              height="8.7"
              fill="#3d4852"
              opacity="0.2"/>
          </g>
        </g>
        <g id="buttons" ref="buttons">
          <g>
            <rect
              x="117.3"
              y="242.1"
              width="56.1"
              height="17.4"
              rx="4.3"
              ry="4.3"
              fill="#3d4852"
              opacity="0.2"/>
            <line
              x1="132.3"
              y1="250.8"
              x2="158.4"
              y2="250.8"
              fill="none"
              stroke="#3d4852"
              stroke-miterlimit="10"
              stroke-width="4.35"/>
          </g>
          <g>
            <rect
              x="185.6"
              y="243.2"
              width="49"
              height="15.22"
              rx="3.8"
              ry="3.8"
              fill="none"
              stroke="#3d4852"
              stroke-miterlimit="10"
              stroke-width="4.35"
              opacity="0.2"/>
            <line
              x1="202.1"
              y1="250.8"
              x2="218.1"
              y2="250.8"
              fill="none"
              stroke="#3d4852"
              stroke-miterlimit="10"
              stroke-width="4.35"/>
          </g>
        </g>
        <g id="sprinkles" ref="sprinkles" opacity="0.5">
          <use
            width="46.5"
            height="41.51"
            transform="translate(103.4 98.8) rotate(76.7) scale(0.48)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(41.3 131.5) rotate(59.5) scale(0.48)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(93 205.2) rotate(84.5) scale(0.48)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="matrix(-0.04, 0.41, -0.41, -0.04, 115.57, 279.8)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="matrix(0.06, 0.38, -0.38, 0.06, 168.59, 332.86)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(254 368) rotate(34.8) scale(0.48)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(393.8 381.1) rotate(-0.4) scale(0.48)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="matrix(0.28, -0.24, 0.24, 0.28, 532.21, 362.94)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(475 242.2) rotate(-54.3) scale(0.46)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(506.7 127.5) rotate(-29.9) scale(0.48)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(397.4 50.9) rotate(-12.1) scale(0.48)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(209.4 57.1) rotate(95.5) scale(0.41)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="matrix(0.08, 0.45, -0.45, 0.08, 150.09, 44.14)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="46.5"
            height="41.51"
            transform="translate(307 52.4) rotate(68.5) scale(0.42)"
            xlink:href="#triangle"
            class="sprinkle-triangle"/>
          <use
            width="42"
            height="42"
            transform="translate(52.7 82.2) scale(0.32)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(0 178.4) scale(0.35)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(35 228.5) scale(0.41)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(54 304.7) scale(0.38)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(120.6 369.3) scale(0.3)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(179.6 291.1) scale(0.29)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(222 310.7) scale(0.35)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(208.1 371.9) scale(0.33)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(328.4 380.6) scale(0.42)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(479 354.1) scale(0.36)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(505.3 283.8) scale(0.38)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(547.2 225.4) scale(0.39)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(487.4 166.8) scale(0.42)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(453.2 70.8) scale(0.43)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(359.5 70.6) scale(0.43)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(332.3 15) scale(0.35)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(259.1 21) scale(0.3)"
            xlink:href="#circle"/>
          <use
            width="42"
            height="42"
            transform="translate(234 69.6) scale(0.35)"
            xlink:href="#circle"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { TweenMax, CSSPlugin, Power3, Sine, Linear } from 'gsap/all'
import { shuffle } from 'lodash'

const gsapPlugins = [CSSPlugin]

export default {
  data() {
    return {
      startDelay: 0.3,
      svgOrigin: ''
    }
  },
  mounted() {
    const { width, height } = this.$el.viewBox.baseVal
    this.svgOrigin = `${width / 2} ${height / 2}`
    this.animate()
    this.startDelay = 0
  },
  destroyed() {
    TweenMax.killAll()
  },
  methods: {
    animate() {
      TweenMax.killAll()
      this.animateMains()
      this.animateSprinkles()
    },
    animateMains() {
      let els = [
        this.$refs.window1,
        this.$refs.window2,
        this.$refs.list,
        this.$refs.buttons
      ]
      els = shuffle(els)
      TweenMax.staggerFromTo(
        els,
        1,
        {
          opacity: 0,
          y: '36px'
        },
        {
          opacity: 1,
          y: '0px',
          delay: this.startDelay + 0.6,
          ease: Power3.easeOut
        },
        0.06,
        this.yoyoFeatures
      )
    },
    animateSprinkles() {
      let els = this.$refs.sprinkles.children
      els = shuffle(els)
      const stagger = 0.01
      const duration = 2
      TweenMax.set(els, { opacity: 1 })
      TweenMax.staggerFrom(
        els,
        duration,
        {
          scale: 0,
          delay: this.startDelay,
          svgOrigin: this.svgOrigin,
          ease: Power3.easeOut
        },
        stagger
      )
      TweenMax.staggerFrom(
        els,
        duration * 2,
        {
          opacity: 0,
          delay: this.startDelay,
          ease: Power3.easeOut
        },
        stagger,
        this.rotateTriangles
      )
    },
    yoyoSprinkles() {
      let els = this.$refs.sprinkles.children
      els = shuffle(els)
      const stagger = 0.1
      const duration = 3
      TweenMax.staggerTo(
        els,
        duration,
        {
          y: '+=10',
          yoyo: true,
          repeat: -1,
          ease: Sine.easeInOut
        },
        stagger
      )
    },
    rotateTriangles() {
      const triangles = [
        ...this.$refs.sprinkles.querySelectorAll('.sprinkle-triangle')
      ]
      TweenMax.staggerTo(
        triangles,
        12,
        {
          rotation: '+=360',
          transformOrigin: '50% 50%',
          repeat: -1,
          ease: Linear.easeNone
        },
        0.05
      )
    },
    yoyoFeatures() {
      let els = [
        this.$refs.window1,
        this.$refs.window2,
        this.$refs.list,
        this.$refs.buttons
      ]
      els = shuffle(els)
      TweenMax.staggerTo(
        els,
        3,
        {
          y: '+=10',
          yoyo: true,
          repeat: -1,
          ease: Sine.easeInOut
        },
        0.3
      )
    }
  }
}
</script>

<style>
#window-1,
#window-2,
#list,
#buttons,
#sprinkles * {
  will-change: transform, opacity;
}
</style>
